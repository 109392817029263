.animationContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.bounceIn {
  animation: bounceIn 1s linear;
}

.liked {
  color: var(--red);
}

.disliked {
  color: var(--foreground);
}

.icon {
  filter: drop-shadow(0 0 2px #00000030);
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  15% {
    opacity: 1;
    transform: scale(1.1);
  }
  30% {
    transform: scale(0.9);
  }
  45%,
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}
