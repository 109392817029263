.container {
  display: flex;
  flex-direction: column;
  z-index: 3;
  height: 100%;
}

.centered {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post {
  flex-grow: 1;
  overflow-y: auto;
}
