.imageContainer {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
