.abbreviatedComment {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.more {
  color: var(--foregroundSecondary);
}

.commentButton {
  margin: 0px;
  padding: 0px;
}
