.toggle {
  --height: 30px;
  --width: calc(var(--height) * 1.75);
  --border-radius: calc(var(--height) * 2);

  display: inline-block;
  cursor: pointer;
}

.fill {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--foregroundQuaternary);
  transition: background-color 0.2s;
}

.fill::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  height: calc(var(--height) - 6px);
  width: calc(var(--height) - 6px);
  background-color: white;
  border-radius: var(--border-radius);
  transition: transform 0.2s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
}

.input {
  display: none;
}

.input:checked ~ .fill {
  background-color: var(--green);
}

.input:checked ~ .fill::after {
  transform: translateX(calc(var(--width) - var(--height)));
}
