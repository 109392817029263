.form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.error {
  background-color: var(--red);
  padding: 10px;
  border-radius: 6px;
  margin: 10px 10px 0px 10px;
}
