.container {
  font-size: 14px;
  display: flex;
  gap: 14px;
}

.text {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  align-self: flex-start;
}

.thumbnail {
  flex-shrink: 0;
  height: 45px;
  width: 45px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
