.container {
  display: flex;
  flex-direction: column;
  z-index: 5;
  height: 100%;
}

.contentContainer {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
}

.content {
  margin: auto;
  width: 100%;
}

.footer {
  border-top: 1px solid var(--separatorColor);
}
