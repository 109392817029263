.container {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.marginLeftAuto {
  margin-left: auto;
}

.tabs {
  justify-content: space-evenly;
}

.topBarLogo {
  font-size: 24px;
}

.topNav {
  justify-content: space-between;
}

.topNavLeft {
  display: flex;
  align-items: center;
}

.topNavRight {
  display: flex;
  align-items: center;
  gap: 12px;
}
