.tagPicker {
  padding: 10px;
  border: 1px solid var(--separatorColor);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 52px;
  margin: 10px 10px 0px 10px;
  cursor: text;
}

.input {
  padding: 0px;
  margin: 0px;
  width: 100px;
  border: none;
  outline: none;
  background-color: var(--background);
  color: var(--foreground);
}

.input::placeholder {
  color: var(--foregroundTertiary);
}

.manageButton,
.createButton {
  flex-shrink: 0;
  padding: 0px;
  margin: 0px;
  font-size: small;
  padding: 0px 10px;
  height: 36px;
}

.createButton {
  border-left: 1px solid var(--separatorColor);
}

.footer {
  display: flex;
  align-items: center;
}

.tagList {
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
  gap: 10px;
  padding: 10px;
  min-height: 50px;
}

.tagList::-webkit-scrollbar {
  display: none;
}
