.topBar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  width: 100%;
  background-color: var(--background);
  border-bottom: 1px solid var(--separatorColor);
  padding: 0px 6px;
  font-size: large;
  font-weight: 600;
}
