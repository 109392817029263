.container {
  display: flex;
  gap: 6px;
  padding: 4px 0px;
}

.input {
  flex: 1;
}

.button {
  padding: 0px;
  font-size: 16px;
}
