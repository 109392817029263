.uploadControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.pasteArea {
  margin-top: 18px;
  padding: 18px;
  border: 1px dashed var(--separatorColor);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.pasteArea:empty::before {
  content: "Paste image";
  color: var(--foregroundTertiary);
}

.pasteArea:focus::before {
  color: transparent;
}

.imagePreviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0px 20px;
  overflow-y: auto;
}

.imagePreviews > img {
  max-height: 120px;
}
