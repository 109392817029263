.deleteLayer {
  background-color: var(--red);
  position: relative;
  z-index: 0;
}

.deleteLayer::after {
  content: "Delete";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: -1;
}

.swipeLayer {
  background-color: var(--background);
  padding: 0px 14px;
}

.content {
  padding-bottom: 4px;
  font-size: 14px;
}
