.controlsContainer {
  background-color: var(--background);
  border-bottom: 1px solid var(--separatorColor);
}

.filterBar {
  overflow-x: auto;
  padding: 0px 6px;
  gap: 6px;
}

.filterBar::-webkit-scrollbar {
  display: none;
}

.pillButton {
  flex-shrink: 0;
  padding: 8px 16px;
  margin: 0px;
  background: none;
  outline: none;
  border: 1px solid var(--separatorColor);
  color: var(--foreground);
  border-radius: 20px;
  font-size: small;
  min-width: 50px;
}

.active {
  background-color: var(--foreground);
  color: var(--background);
}

.selectorContainer {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 6px;
  background-color: var(--background);
  color: var(--foreground);
}

.selector {
  color: var(--foreground);
  font-size: small;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  background: none;
  appearance: none;
}
