.container {
  position: relative;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 4px;
  border-radius: 20px;
  background-color: var(--red);
  min-width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 51%;
}
