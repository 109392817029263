.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  border-bottom: 1px solid var(--separatorColor);
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px;
}

.item {
  padding: 2px;
  position: relative;
}

.albumIcon {
  font-size: 20px;
  filter: drop-shadow(0 0 2px #00000075);
  position: absolute;
  top: 8px;
  right: 10px;
}
