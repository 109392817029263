.rateControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  border-top: 1px solid var(--separatorColor);
  width: 100%;
  padding: 12px 0px;
}

.fab {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 56px;
  height: 56px;
  border: none;
}

.likeFab {
  color: var(--white);
  background-color: var(--red);
  border: none;
}

.dislikeFab {
  background-color: var(--foregroundQuaternary);
  color: var(--foreground);
  border: none;
}
