.commentsContainer {
  display: flex;
  flex-direction: column;
  z-index: 4;
  height: 100%;
}

.centered {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comments {
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0px;
}
