.feedControls {
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;
}

.liked {
  color: var(--red);
}
