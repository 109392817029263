.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.section {
  padding: 12px;
}

.title {
  font-size: large;
  padding: 12px;
}

.form {
  display: flex;
  flex-direction: column;
}

.preference {
  display: flex;
  align-items: center;
  gap: 12px;
}

.description {
  flex-grow: 1;
  flex-shrink: 0;
}

.searchInput {
  border: 1px solid var(--separatorColor);
  border-radius: 6px;
  padding: 10px;
}

.searchRow {
  display: flex;
  align-items: center;
  gap: 6px;
}
