.topLeft {
  flex: 1;
}

.topRight {
  flex: 1;
  text-align: right;
}

.hidden {
  visibility: hidden;
}

.show {
  animation: slideIn 0.2s ease-out;
}

.hide {
  animation: slideOut 0.2s ease-out;
  visibility: hidden;
}

@keyframes slideIn {
  0% {
    left: 100vw;
  }

  100% {
    left: 0px;
  }
}

@keyframes slideOut {
  0% {
    left: 0px;
    visibility: visible;
  }

  100% {
    left: 100vw;
    visibility: hidden;
  }
}
