.container {
  position: relative;
  display: inline-block;
  background-color: none;
}

.menuBackdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  min-width: 140px;
  background-color: var(--backgroundSecondary);
  border-radius: 4px;
  border: 1px solid var(--separatorColor);
  box-shadow: 0 3px 10px rgb(0 0 0 / 1);
  padding: 6px;
}

.menuItem {
  font-size: 14px;
  width: 100%;
  text-align: left;
}
