html {
  box-sizing: border-box;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--foreground);
  background-color: var(--background);
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Prevents iOS from zooming in on inputs */
select,
textarea,
input {
  font-size: 16px;
}

:root {
  --background: #000000;
  --backgroundSecondary: #161616;
  --foreground: #ffffff;
  --foregroundSecondary: #9a9aa0;
  --foregroundTertiary: #5e5e61;
  --foregroundQuaternary: #333333;
  --separatorColor: #292929;
  --red: #ed4956;
  --green: #2fd058;
}
