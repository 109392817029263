.relative {
  position: relative;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 0px 10px 10px 10px;
  font-size: 14px;
}

.viewAllCommentsButton {
  color: var(--foregroundSecondary);
  background: none;
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

.partnerLikeContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.separator {
  border-bottom: 1px solid var(--separatorColor);
}
