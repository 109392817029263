.tag {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  font-size: small;
  color: var(--foreground);
  background-color: var(--background);
  border: 1px solid var(--separatorColor);
  margin: 0px;
  height: 30px;
  gap: 6px;
  padding: 0px 8px;
}

.text {
  flex-shrink: 0;
}

.tag.active {
  background-color: var(--foreground);
  color: var(--background);
  border: none;
}
