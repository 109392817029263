.button {
  border: none;
  outline: none;
  background: none;
  font-size: large;
  font-weight: normal;
  padding: 10px;
  color: var(--foreground);
}

.button:disabled {
  color: var(--foregroundTertiary);
}
