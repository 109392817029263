.input {
  display: block;
  width: 100%;
  color: var(--foreground);
  background-color: transparent;
  border-radius: 0px;
  border-bottom: 1px solid var(--separatorColor);
  border-right: none;
  border-left: none;
  border-top: none;
  outline: none;
  padding: 8px;
}

.input::placeholder {
  color: var(--foregroundTertiary);
}
