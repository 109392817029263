.ratePostsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentContainer {
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  text-align: center;
  margin: auto;
  width: 100%;
}

.centeredText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.imagesContainer {
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.images {
  margin: auto;
  width: 100%;
}
